const base_url = window.env.REACT_APP_BASE_URL;
const storeId = localStorage.getItem('storeId');

export const printReceipt = async (order, receiptContent, setError, setLoading) => {
    try {
        // Set loading to true at the start of the operation
        setLoading(true);

        const response = await fetch(`${base_url}printers?store_id=${storeId}`);
        const data = await response.json();

        // Get the receipt content
        const content = receiptContent(order);

        // Display the receipt content in the console
        console.log(content);
        
        if (data && data.length > 0) {
            const printer = data.find(printer => printer.activated === true);

            if (window.epson) {
                const device = new window.epson.ePOSDevice();
                if(printer){
                    // Send print command
                    device.connect(printer.ip, device.IFPORT_EPOSPRINT, (resultConnect) => {
                        if (resultConnect === 'OK' || resultConnect === 'SSL_CONNECT_OK') {
                            device.createDevice(
                                device.DEVICE_TYPE_PRINTER,
                                device.IF_EPOSPRINT,
                                { crypto: true, buffer: false },
                                (printerDevice, errorCode) => {
                                    if (printerDevice) {
                                        // Use the receipt content for printing
                                        printerDevice.addTextAlign(printerDevice.ALIGN_CENTER);
                                        printerDevice.addText(content);
                                        printerDevice.addCut(printerDevice.CUT_FEED);

                                        // Send the print job
                                        printerDevice.send((response) => {
                                            // Set loading to false after print job is sent
                                            setLoading(false);

                                            if (response.success) {
                                                setError('Impression avec succès !');
                                                setTimeout(() => setError(''), 1000);
                                                return;
                                            } else {
                                                console.error('Print failed:', response);
                                                setError('Échec de l\'impression !');
                                                setTimeout(() => setError(''), 1000);
                                                return;
                                            }
                                        });
                                    } else {
                                        console.error('Failed to create printer object:', errorCode);
                                        setLoading(false);
                                        setError('Échec de l\'impression !');
                                        setTimeout(() => setError(''), 1000);
                                        return;
                                    }
                                }
                            );
                        } else {
                            console.error('Failed to connect to the printer:', resultConnect);
                            setLoading(false);
                            setError('Échec de l\'impression !');
                            setTimeout(() => setError(''), 1000);
                            return;
                        }
                    }); 
                }else{
                    setLoading(false);
                    setError('Pas d\'imprimante activée!');
                    setTimeout(() => setError(''), 1000);
                    return;
                }
            } else {
                setLoading(false);
                console.error('Epson ePOS SDK not found');
            }
        } else {
            setLoading(false);
            setError('Imprimante manquante!');
            setTimeout(() => setError(''), 1000);
            return;
        }
    } catch (error) {
        setLoading(false);
        console.error('Error occurred while trying to print:', error);
        setError('Échec de l\'impression !');
        setTimeout(() => setError(''), 1000);
        return;
    }
};
