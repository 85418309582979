import React, { useState, useEffect, useCallback } from 'react';
import { FaArrowRight, FaMapMarkerAlt, FaClock } from 'react-icons/fa';
import './Orders.css';
import ImageHandler from '../ImageHandler/ImageHandler';
import { DateTime } from 'luxon';
import { printReceipt } from '../print';
import axios from 'axios';

const Orders = () => {
    const [orders, setOrders] = useState([]);
    const [filter, setFilter] = useState('Reçue');
    const [selectedOrder, setSelectedOrder] = useState(null);
    const [popupVisible, setPopupVisible] = useState(false);
    const [popupType, setPopupType] = useState(null);
    const [notification, setNotification] = useState(null);
    const [paymentTypes, setPaymentTypes] = useState([]);
    const { fetchImageUrl } = ImageHandler();
    const base_url = window.env.REACT_APP_BASE_URL;
    const base_url_wss = window.env.REACT_APP_BASE_URL_WSS;
    const storeId = localStorage.getItem('storeId');
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const [storeInfo, setStoreInfo] = useState({ name: '', address: '', siret: '' });
    const [storeParams, setStoreParams] = useState({ name: '', address: '', siret: '' });
    const jwt = localStorage.getItem('jwt');
    const [deliveryFee, setDeliveryFee] = useState(0);

    // Function to fetch orders based on the filter
    const fetchOrders = useCallback(async () => {
        if (!storeId) {
            console.error("L'identifiant du magasin est manquant dans le stockage local");
            return;
        }

        let url = `${base_url}inprogress?store_id=${storeId}`;
        switch (filter) {
            case 'Tous':
                url = `${base_url}orders/?store_id=${storeId}`;
                break;
            case 'Reçue':
                // No specific URL, handled via WebSocket
                break;
            case 'Terminée':
                url = `${base_url}done?store_id=${storeId}`;
                break;
            default:
                url = `${base_url}inprogress?store_id=${storeId}`;
        }

        if (filter !== 'Reçue') {
            try {
                setNotification(null);
                const response = await fetch(url);
                const data = await response.json();
                if (Array.isArray(data)) {
                    const ordersWithImages = await Promise.all(
                        data.map(async (order) => {
                            if (Array.isArray(order.menus)) {
                                const menusWithImages = await Promise.all(
                                    order.menus.map(async (menu) => {
                                        if (menu.menu_image_url) {
                                            const imageUrl = await fetchImageUrl(menu.menu_image_url);
                                            return { ...menu, menu_image_url: imageUrl, image_loaded: true };
                                        }
                                        return { ...menu, image_loaded: false };
                                    })
                                );
                                return { ...order, menus: menusWithImages };
                            }
                            return { ...order, menus: [] };
                        })
                    );
                    setOrders(ordersWithImages);
                } else {
                    setOrders([]);
                    setNotification(null);
                }
            } catch (error) {
                console.error("Erreur lors de la récupération des commandes :", error);
            }
        } else if (filter === 'Reçue') {
            // Clear orders to prevent displaying stale data
            setOrders([]);
            setNotification(null);

            const socket = new WebSocket(`${base_url_wss}ws/received/${storeId}/`);

            socket.onmessage = async function (event) {
                let incomingOrders = JSON.parse(event.data);

                if (Array.isArray(incomingOrders)) {
                    const newOrdersWithImages = await Promise.all(
                        incomingOrders.map(async (order) => {
                            if (Array.isArray(order.menus)) {
                                const menusWithImages = await Promise.all(
                                    order.menus.map(async (menu) => {
                                        if (menu.menu_image_url) {
                                            const imageUrl = await fetchImageUrl(menu.menu_image_url);
                                            return { ...menu, menu_image_url: imageUrl, image_loaded: true };
                                        }
                                        return { ...menu, image_loaded: false };
                                    })
                                );
                                return { ...order, menus: menusWithImages };
                            }
                            return { ...order, menus: [] };
                        })
                    );

                    const filteredOrders = newOrdersWithImages.filter(order => order.status === 'Reçue');

                    setOrders((prevOrders) => {
                        const existingOrders = prevOrders.filter(order => order.status === 'Reçue');
                        const updatedOrders = [
                            ...existingOrders,
                            ...filteredOrders.filter(newOrder =>
                                !existingOrders.some(existingOrder => existingOrder.order_number === newOrder.order_number)
                            )
                        ];

                        return updatedOrders;
                    });

                    const newReceivedOrder = filteredOrders.slice().reverse().find(order => order.status === 'Reçue');
                    if (newReceivedOrder) {
                        setNotification(`Vous avez reçu une nouvelle commande : #${newReceivedOrder.order_number}`);

                        const playNotificationSound = () => {
                            const audio = new Audio('/notification.ogg');
                            audio.play().catch((error) => {
                                console.log('Playback prevented:', error.message);
                            });
                        };

                        if (Notification.permission !== 'granted') {
                            Notification.requestPermission();
                        }

                        if (Notification.permission === 'granted') {
                            new Notification('Nouvelle commande reçue', {
                                body: `Commande #: ${newReceivedOrder.order_number}`,
                            });
                        }

                        playNotificationSound();
                    } else {
                        setNotification(null);
                    }
                } else if (incomingOrders && typeof incomingOrders === 'object') {
                    if (Array.isArray(incomingOrders.menus)) {
                        const singleOrderWithImages = {
                            ...incomingOrders,
                            menus: await Promise.all(
                                incomingOrders.menus.map(async (menu) => {
                                    if (menu.menu_image_url) {
                                        const imageUrl = await fetchImageUrl(menu.menu_image_url);
                                        return { ...menu, menu_image_url: imageUrl, image_loaded: true };
                                    }
                                    return { ...menu, image_loaded: false };
                                })
                            )
                        };

                        if (singleOrderWithImages.status === 'Reçue') {
                            setOrders((prevOrders) => {
                                const existingOrders = prevOrders.filter(order => order.status === 'Reçue');
                                const updatedOrders = [
                                    ...existingOrders.filter(existingOrder => existingOrder.order_number !== singleOrderWithImages.order_number),
                                    singleOrderWithImages
                                ];

                                return updatedOrders;
                            });

                            setNotification(`Vous avez reçu une nouvelle commande : #${singleOrderWithImages.order_number}`);

                            const playNotificationSound = () => {
                                const audio = new Audio('/notification.ogg');
                                audio.play().catch((error) => {
                                    console.log('Playback prevented:', error.message);
                                });
                            };

                            if (Notification.permission !== 'granted') {
                                Notification.requestPermission();
                            }

                            if (Notification.permission === 'granted') {
                                new Notification('Nouvelle commande reçue', {
                                    body: `Commande #: ${singleOrderWithImages.order_number}`,
                                });
                            }

                            playNotificationSound();
                        }
                    }
                } else {
                    console.error('Unexpected data format:', incomingOrders);
                    setOrders([]);
                    setNotification(null);
                }
            };

            socket.onopen = function () {
                socket.send(JSON.stringify({ message: 'Request orders' }));
            };

            socket.onclose = function (event) {
                console.log('WebSocket closed:', event);
            };

            socket.onerror = function (error) {
                console.error('WebSocket error:', error);
            };

            // Cleanup WebSocket connection on component unmount
            return () => {
                if (socket) {
                    socket.close();
                }
            };
        }
        return undefined;
    }, [filter, fetchImageUrl]);

    // Fetch Delivery Fee
    const fetchDeliveryFee = async () => {
        try {
            const response = await fetch(`${base_url}parameter-store/?store_id=${storeId}`);
            const data = await response.json();
            setDeliveryFee(data.delivery_price || 0); 
        } catch (error) {
            console.error('Error fetching delivery fee:', error);
        }
    };

    useEffect(() => {
        fetchOrders();
        fetchDeliveryFee(); 
    }, [fetchOrders]);

    const storeInformation = async () => {
        try {
            const response = await fetch(`${base_url}getStore/?id=${storeId}`);
            const data = await response.json();
            setStoreInfo(data);
        } catch (error) {
            console.error("Erreur lors de la récupération des paramètres du store :", error);
        }
    };

    const storeParameters = async () => {
        try {
            const url = `${base_url}parameter-store/?store_id=${storeId}`;
            const res = await axios.get(url, {
                headers: {
                    Authorization: `Bearer ${jwt}`
                },
                withCredentials: true
            });
            setStoreParams(res.data);
        } catch (err) {
            console.error('Error fetching store parameters:', err);
            setError(`Failed to fetch store parameters. Please try again later. Error: ${err.message}, Details: ${err.response?.data}`);
        }
    };

    useEffect(() => {
        storeParameters();
        storeInformation();
        fetchPaymentTypes();
    }, [fetchOrders]);

    const fetchPaymentTypes = async () => {
        try {
            const response = await fetch(`${base_url}payment-types/`);
            const data = await response.json();
            setPaymentTypes(data);
        } catch (error) {
            console.error("Erreur lors de la récupération des types de paiement :", error);
        }
    };

    const handleFilterClick = (filter) => {
        setFilter(filter);
    };

    const updateOrderStatus = async (orderNumber, newStatus) => {
        try {
            const url = `${base_url}set_status_order/`;
            const response = await fetch(url, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ order_number: orderNumber, status: newStatus }),
            });

            if (response.ok) {
                fetchOrders();
                setPopupVisible(false);
                setPopupType(null);
            } else {
                console.error('Erreur lors de la mise à jour du statut');
            }
        } catch (error) {
            console.error("Erreur lors de la mise à jour du statut :", error);
        }
    };

    const handleStatusChange = (order) => {
        setSelectedOrder(order);
        if (order.status === 'Reçue') {
            setPopupType('En cours');
        } else if (order.status === 'En cours') {
            setPopupType('Terminée');
        }
        setPopupVisible(true);
    };

    const confirmStatusChange = () => {
        let newStatus;
        if (popupType === 'En cours') {
            newStatus = 'En cours';
            printReceipt(selectedOrder, receiptContent, setError, setLoading);
        } else if (popupType === 'Terminée') {
            newStatus = 'Terminée';
        }
        updateOrderStatus(selectedOrder.order_number, newStatus);
    };

    // Function to generate the receipt content
    const receiptContent = (order) => {
        let receiptContent = '';

        receiptContent += '-----------------------------\n';
        receiptContent += `Restaurant ${storeInfo.name}\n`;
        receiptContent += `${storeInfo.address}\n`;
        receiptContent += `${storeInfo.siret}\n`;
        receiptContent += `Tel ${storeParams.phone_number}\n`;
        receiptContent += `Numero de tva ${storeParams.fiscal_number}\n\n`;

        receiptContent += '-----------------------------\n';
        receiptContent += 'Commande #' + order.order_number + '\n';

        if (order.is_planned) {
            receiptContent += 'À emporter\n';
        } else {
            receiptContent += 'En livraison\n';
        }

        receiptContent += `Le ${order.date} à ${DateTime.fromFormat(order.time, "HH:mm:ss").toFormat("HH:mm")}\n`;

        let totalHT = 0;
        let totalTTC = 0;

        order.menus.forEach(menu => {
            receiptContent += '\n-----------------------------\n';
            receiptContent += `Menu: ${menu.menu_name}\n`;
            receiptContent += `Quantity: ${menu.quantity}\n\n`;
            receiptContent += '--------------\n';

            if (menu.selected_ingredients.length > 0) {
                receiptContent += 'Ingredients:\n';
                menu.selected_ingredients.forEach(ingredient => {
                    receiptContent += `- ${ingredient.name}\n`;
                });
            }

            if (menu.selected_supplements.length > 0) {
                receiptContent += '\nSupplements:\n';
                menu.selected_supplements.forEach(supplement => {
                    receiptContent += `- ${supplement.name}\n`;
                });
            }

            if (menu.free_choice) {
                receiptContent += `Choix libre: ${menu.free_choice}\n`;
            }

            if (menu.tva) {
                receiptContent += `HT: ${menu.ht}  `;
                receiptContent += `TVA: (${menu.tva}%)  `;
                receiptContent += `TCC: ${menu.ttc}\n`;

                totalHT += parseFloat(menu.ht);
            }

            totalTTC += parseFloat(menu.ttc);
        });

        receiptContent += '\n-----------------------------\n';
        if (totalTTC !== 0) {
            receiptContent += `Total En HT: ${totalHT.toFixed(2)}\n`;
        }
        receiptContent += `Total En TTC: ${totalTTC.toFixed(2)}\n`;

        receiptContent += `\n\nPaiement en ${order.payment_type.name}\n`;
        receiptContent += '-----------------------------\n\n';
        receiptContent += 'Merci de votre visite\n';

        return receiptContent;
    };

    const closePopup = () => {
        setPopupVisible(false);
        setSelectedOrder(null);
        setPopupType(null);
    };

    const formatPhoneNumber = (phoneNumber) => {
        if (!phoneNumber.startsWith('+33')) {
            return `+33${phoneNumber}`;
        }
        return phoneNumber;
    };

    return (
        <div className="orders-container">
            {error && (
                <div className="errorResult">
                    Erreur : {error}
                </div>
            )}
            {notification && <div className="notification">{notification}</div>}
            <div className="filter">
                <ul className="filters-menu">
                    <li onClick={() => handleFilterClick('Tous')} className={filter === 'Tous' ? 'active' : ''}>Tous</li>
                    <li onClick={() => handleFilterClick('Reçue')} className={filter === 'Reçue' ? 'active' : ''}>Reçues</li>
                    <li onClick={() => handleFilterClick('En cours')} className={filter === 'En cours' ? 'active' : ''}>En cours</li>
                    <li onClick={() => handleFilterClick('Terminée')} className={filter === 'Terminée' ? 'active' : ''}>Terminées</li>
                </ul>
            </div>
            <div className="order-list">
                {orders.length === 0 ? (
                    <p>Aucune commande à afficher.</p>
                ) : (
                    orders.map(order => (
                        <div key={order.id} className="order-item">
                            <div className="order-header">
                                <h3>Commande #{order.order_number}</h3>
                                <div className="order-header-content">
                                    <p>Le {order.date}</p>
                                    <p>
                                    <FaClock /> À : 
                                    {order.time ? 
                                        DateTime.fromFormat(order.time, "HH:mm:ss").isValid ? 
                                            DateTime.fromFormat(order.time, "HH:mm:ss").toFormat("HH:mm") 
                                        : 
                                            'Heure non disponible' 
                                    : 'Heure non disponible'}
                                </p>
                                </div>
                                <span className="status">{order.status}</span>
                            </div>

                            <div className="client-info">
                                <h4>Client</h4>
                                <p>Nom : {order.client.last_name}</p>
                                <p>Prénom : {order.client.first_name}</p>
                                <p>Téléphone : {formatPhoneNumber(order.client.phone)}</p>
                                {order.is_now && !order.is_planned && (
                                    <p>
                                        <FaMapMarkerAlt /> Livrée vers : {order.client.address}
                                    </p>
                                )}
                               {order.is_planned && (
                                       <p>
                                       <FaClock /> À emporter à : 
                                       {order.time ? 
                                           DateTime.fromFormat(order.time, "HH:mm:ss").isValid ? 
                                               DateTime.fromFormat(order.time, "HH:mm:ss").toFormat("HH:mm") 
                                           : 
                                               'Heure non disponible' 
                                       : 'Heure non disponible'}
                                   </p>
                                    )}

                            </div>
                            <br /><h4>Détails du menu</h4><br />
                            <div className="order-details-container">
                                {order.menus.map((menu, index) => (
                                    <div key={index} className="menu-details-box">
                                        <div className="menu-header">
                                            {menu.image_loaded ? (
                                                <img
                                                    src={menu.menu_image_url}
                                                    alt={menu.menu_name}
                                                    className="menu-image"
                                                />
                                            ) : (
                                                <div className="placeholder-image" />
                                            )}
                                            <div className="menu-info">
                                                <h3 className="menu-name">{menu.menu_name}</h3>
                                                <p className="menu-quantity">Quantité: {menu.quantity}</p>
                                            </div>
                                        </div>
                                        <div className="menu-ingredients">
                                            <h4>Ingrédients</h4>
                                            <ul>
                                                {menu.selected_ingredients.length > 0 ?
                                                    (menu.selected_ingredients.map((ingredient, idx) => (
                                                        <li key={idx}>{ingredient.name}</li>
                                                    ))
                                                    ) : (
                                                        <p style={{ color: 'gray', fontSize: '0.8em' }}>pas d'ingrédients</p>
                                                    )}
                                            </ul>
                                            <h4>Suppléments</h4>
                                            <ul>
                                                {menu.selected_supplements.length > 0 ? (
                                                    menu.selected_supplements.map((supplement, idx) => (
                                                        <li key={idx}>{supplement.name}</li>
                                                    ))
                                                ) : (
                                                    <p style={{ color: 'gray', fontSize: '0.8em' }}>pas de suppléments</p>
                                                )}
                                            </ul>
                                            {menu.free_choice !== '' &&
                                                <>
                                                    <h4>Instructions spécifiques</h4>
                                                    <ul>
                                                        <li>{menu.free_choice}</li>
                                                    </ul>
                                                </>
                                            }
                                        </div>
                                    </div>
                                ))}
                            </div>
                            <h4 style={{ marginBottom: '15px', marginTop: '20px' }}>Paiement</h4>
                            <div className='client-info'>
                                <p>Total : {
                                    order.menus.reduce((acc, menu) => {
                                        const ingredientsTotal = menu.selected_ingredients.reduce((sum, ingredient) => sum + parseFloat(ingredient.price), 0);
                                        const supplementsTotal = menu.selected_supplements.reduce((sum, supplement) => sum + parseFloat(supplement.price), 0);
                                        return acc + (parseFloat(menu.menu_price) + ingredientsTotal + supplementsTotal) * menu.quantity;
                                    }, 0).toFixed(2)
                                }€</p>
                                {order.client.address && !order.is_planned ? (
                                    <>
                                        {deliveryFee > 0 ? (
                                            <>
                                                <p className="delivery-fee">Frais de livraison : {deliveryFee.toFixed(2)}€</p>
                                                <p className="total-with-delivery">Total avec livraison : {
                                                    (order.menus.reduce((acc, menu) => {
                                                        const ingredientsTotal = menu.selected_ingredients.reduce((sum, ingredient) => sum + parseFloat(ingredient.price), 0);
                                                        const supplementsTotal = menu.selected_supplements.reduce((sum, supplement) => sum + parseFloat(supplement.price), 0);
                                                        return acc + (parseFloat(menu.menu_price) + ingredientsTotal + supplementsTotal) * menu.quantity;
                                                    }, 0) + deliveryFee).toFixed(2)
                                                }€</p>
                                            </>
                                        ) : (
                                            <p className="delivery-fee">Livraison gratuite</p>
                                        )}
                                    </>
                                ) : null}

                                {order.payment_type && (
                                    <p>Mode : {order.payment_type.name}</p>
                                )}
                                {order.status !== 'Terminée' && (
                                    <FaArrowRight className="status-arrow" onClick={() => handleStatusChange(order)} />
                                )}
                            </div>
                        </div>
                    ))
                )}
            </div>
            {popupVisible && (
                <div className="popupp">
                    <div className="popupp-content">
                        {popupType === 'En cours' && (
                            <h3>Voulez-vous changer le statut de la commande #{selectedOrder.order_number} à En cours ?</h3>
                        )}
                        {popupType === 'Terminée' && (
                            <h3>Voulez-vous changer le statut de la commande #{selectedOrder.order_number} à Terminée ?</h3>
                        )}
                        <button onClick={confirmStatusChange}>Oui</button>
                        <button className="non-button" onClick={closePopup}>Non</button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default Orders;
