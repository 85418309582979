import React, { useState, useEffect } from 'react';
import './Ajouts.css';
import { FaEdit, FaTrash } from 'react-icons/fa';
import axios from 'axios';
import ConfirmDialog from '../other/confirmDialog'; // Assurez-vous que le chemin est correct

const Ajouts = () => {
  const [tags, setTags] = useState([]);
  const [newTag, setNewTag] = useState('');
  const [supp, setSupp] = useState(false);
  const [ingr, setIngr] = useState(false);
  const [editingTag, setEditingTag] = useState(null);
  const [editTagName, setEditTagName] = useState('');
  const [editSupp, setEditSupp] = useState(false);
  const [editIngr, setEditIngr] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [tagIdToDelete, setTagIdToDelete] = useState(null);
  const storeId = localStorage.getItem('storeId');

  function normalizeString(str) {
    return str.toLocaleLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '');
  }

  function tagExists(newTag, tags) {
    const normalizedNewTag = normalizeString(newTag);
    return tags.some(tag => normalizeString(tag.name) === normalizedNewTag);
  }

  useEffect(() => {
    
    fetchTags(storeId);
  }, [storeId]);

  const fetchTags = async (storeId) => {
    try {
      const url = `${window.env.REACT_APP_BASE_URL}tag/?store_id=${storeId}`;
      const response = await fetch(url);
      const data = await response.json();
      setTags(data.tags);
    } catch (error) {
      console.error('Erreur lors du chargement des ajouts :', error);
    }
  };

  const handleAddTag = async () => {
    try {
      if((supp === false && ingr === false) || newTag === ''){
        setError('Données manquantes!');
        setTimeout(() => {
          setError('');
        }, 1000);
        
      }else if (tagExists(newTag, tags)) {
        setError('Donnée existe déjà!');
        setTimeout(() => {
          setError('');
        }, 1000);
      } else {
        const url = `${window.env.REACT_APP_BASE_URL}tag/`;
        const response = await axios.post(
          url,
          {
            name: newTag,
            store_id: storeId,
            is_supplement: supp,
            is_ingredient: ingr,
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('jwt')}`,
              'Content-Type': 'application/json',
            },
            withCredentials: true,
          }
        );
        if (response.status === 201) {
          fetchTags(storeId);
          setNewTag('');
          setSupp(false);
          setIngr(false);
          setSuccess('Ajouté avec succès !');
          setTimeout(() => {
            setSuccess('');
          }, 1000);
        } else {
          console.error("Échec de l'ajout");
        }
      }
    } catch (error) {
      console.error('Erreur :', error);
    }
  };

  const handleOpenConfirmDialog = (id) => {
    setTagIdToDelete(id);
    setOpenConfirmDialog(true);
  };

  const handleCloseConfirmDialog = () => {
    setOpenConfirmDialog(false);
    setTagIdToDelete(null);
  };

  const handleDeleteTag = async () => {
    try {
      const url = `${window.env.REACT_APP_BASE_URL}tag/`;
      const response = await axios.delete(url, {
        data: { id: tagIdToDelete, store_id: storeId },
        headers: {
          Authorization: `Bearer ${localStorage.getItem('jwt')}`,
          'Content-Type': 'application/json',
        },
        withCredentials: true,
      });
      if (response.status === 200) {
        fetchTags(storeId);
        setSuccess('Supprimé avec succès !');
        setTimeout(() => {
          setSuccess('');
        }, 1000);
      } else {
        console.error('Échec de la suppression');
      }
    } catch (error) {
      console.error('Erreur :', error);
    }
    handleCloseConfirmDialog();
  };

  const handleEditTag = async () => {
    if ((editIngr === false && editSupp === false) || editTagName === '') {
      setError('Données manquantes !');
      setTimeout(() => {
        setError('');
      }, 1000);
    } else {
      try {
        const url = `${window.env.REACT_APP_BASE_URL}tag/`;
        const response = await axios.put(
          url,
          {
            id: editingTag.id,
            name: editTagName,
            store_id: storeId,
            is_supplement: editSupp,
            is_ingredient: editIngr,
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('jwt')}`,
              'Content-Type': 'application/json',
            },
            withCredentials: true,
          }
        );
        if (response.status === 200) {
          setTags((prevTags) =>
            prevTags.map((tag) =>
              tag.id === editingTag.id
                ? {
                    ...tag,
                    name: editTagName,
                    is_supplement: editSupp,
                    is_ingredient: editIngr,
                  }
                : tag
            )
          );
          setEditingTag(null);
          setEditTagName('');
          setEditSupp(false);
          setEditIngr(false);
          setSuccess('Modifié avec succès !');
          setTimeout(() => {
            setSuccess('');
          }, 1000);
        } else {
          console.error('Échec de la modification');
        }
      } catch (error) {
        console.error('Erreur :', error);
      }
    }
  };

  return (
    <div className="tags">
      <div className="add-tag">
        <input
          type="text"
          value={newTag}
          onChange={(e) => setNewTag(e.target.value)}
          placeholder="Nom d'ajout"
        />
        <div className="checkbox-group">
          <label>
            Supplement
            <input
              type="checkbox"
              checked={supp}
              onChange={(e) => setSupp(e.target.checked)}
            />
          </label>
          <label>
            Ingredient
            <input
              type="checkbox"
              checked={ingr}
              onChange={(e) => setIngr(e.target.checked)}
            />
          </label>
        </div>
        <button onClick={handleAddTag}>AJOUTER</button>
      </div>
      {error && <div className="errorResult">Erreur : {error}</div>}
      {success && <div className="successResult">Success : {success}</div>}
      <table>
        <thead>
          <tr>
            <th>Nom d'ajout</th>
            <th>Supplement</th>
            <th>Ingredient</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {tags.map((tag) => (
            <tr key={tag.id}>
              <td>
                {editingTag && editingTag.id === tag.id ? (
                  <input
                    type="text"
                    value={editTagName}
                    onChange={(e) => setEditTagName(e.target.value)}
                  />
                ) : (
                  tag.name
                )}
              </td>
              <td>
                {editingTag && editingTag.id === tag.id ? (
                  <input
                    type="checkbox"
                    checked={editSupp}
                    onChange={(e) => setEditSupp(e.target.checked)}
                  />
                ) : (
                  <input type="checkbox" checked={tag.is_supplement} disabled />
                )}
              </td>
              <td>
                {editingTag && editingTag.id === tag.id ? (
                  <input
                    type="checkbox"
                    checked={editIngr}
                    onChange={(e) => setEditIngr(e.target.checked)}
                  />
                ) : (
                  <input type="checkbox" checked={tag.is_ingredient} disabled />
                )}
              </td>
              <td>
                {editingTag && editingTag.id === tag.id ? (
                  <button className="save-button" onClick={handleEditTag}>
                    Enregistrer
                  </button>
                ) : (
                  <>
                    <FaEdit
                      className="fa-edit"
                      onClick={() => {
                        setEditingTag(tag);
                        setEditTagName(tag.name);
                        setEditSupp(tag.is_supplement);
                        setEditIngr(tag.is_ingredient);
                      }}
                    />
                    <FaTrash
                      className="fa-trash"
                      onClick={() => handleOpenConfirmDialog(tag.id)}
                    />
                    <ConfirmDialog
                      open={openConfirmDialog && tagIdToDelete === tag.id}
                      onClose={handleCloseConfirmDialog}
                      onConfirm={handleDeleteTag}
                      msg={'Êtes-vous sûr de vouloir supprimer cet ajout ? Cette action est irréversible.'}
                    />
                  </>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Ajouts;
