import { useState, useEffect, useCallback } from 'react';
import './Categories.css';
import { FaEdit, FaTrash, FaUpload } from 'react-icons/fa';
import axios from 'axios';
import ImageHandler from '../ImageHandler/ImageHandler';
import ConfirmDialog from '../other/confirmDialog';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

const Categories = () => {
  const [categories, setCategories] = useState([]);
  const [newCategory, setNewCategory] = useState('');
  const [newCategoryImage, setNewCategoryImage] = useState(null);
  const [editingCategory, setEditingCategory] = useState(null);
  const [editCategoryName, setEditCategoryName] = useState('');
  const [error, setError] = useState('');
  const storeId = localStorage.getItem('storeId');
  const { fetchImageUrl, uploadImage } = ImageHandler();

  const fetchCategories = useCallback(async () => {
    try {
      const url = `${window.env.REACT_APP_BASE_URL}category/?store_id=${storeId}`;
      const response = await fetch(url);
      const data = await response.json();
      const categoriesWithImages = await Promise.all(
        data.categories.map(async (category) => {
          if (category.image_url) {
            const imageUrl = await fetchImageUrl(category.image_url);
            return { ...category, image_url: imageUrl, image_loaded: true };
          }
          return { ...category, image_loaded: false };
        })
      );
      setCategories(categoriesWithImages);
    } catch (error) {
      console.error('Erreur lors du chargement des catégories :', error);
    }
  }, [fetchImageUrl, storeId]);

  useEffect(() => {
    
    fetchCategories();
  }, [fetchCategories]);

  const normalizeString = (str) => {
    return str.toLocaleLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '');
  };

  const tagExists = (newTag, tags) => {
    const normalizedNewTag = normalizeString(newTag);
    return tags.some(tag => normalizeString(tag.name) === normalizedNewTag);
  };

  const handleAddCategory = async () => {
  
    if (newCategory === '') {
      setError('Données manquantes!');
      setTimeout(() => setError(''), 1000);
      return;
    }
    if (tagExists(newCategory, categories)) {
      setError('Donnée existe déjà!');
      setTimeout(() => setError(''), 1000);
      return;
    }
  
    try {
      let imageUrl = '';
      if (newCategoryImage) {
        imageUrl = await uploadImage(newCategoryImage); // Appel à la fonction uploadImage pour télécharger l'image
        if (!imageUrl) {
          console.error('Failed to upload image');
          setError('Échec du téléchargement de l\'image');
          setTimeout(() => setError(''), 1000);
          return;
        }
      }
  
      const categoryData = {
        name: newCategory,
        image_url: imageUrl, // Utilisation de l'URL d'image récupérée
        store_id: storeId,
      };
  
      const url = `${window.env.REACT_APP_BASE_URL}category/`;
      const apiResponse = await axios.post(url, categoryData, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('jwt')}`,
          'Content-Type': 'application/json',
        },
        withCredentials: true,
      });
  
      if (apiResponse.status === 201) {
        fetchCategories();
        setNewCategory('');
        setNewCategoryImage(null);
      } else {
        console.error("Échec de l'ajout de la catégorie");
        setError("Échec de l'ajout de la catégorie");
        setTimeout(() => setError(''), 1000);
      }
    } catch (error) {
      console.error('Error adding category:', error);
      if (error.response) {
        console.error('Error response data:', error.response.data);
      }
      setError('Erreur lors de l\'ajout de la catégorie');
      setTimeout(() => setError(''), 1000);
    }
  };

  const [open, setOpen] = useState(false);
  const [categoryId, setCategoryId] = useState(null);

  const handleOpen = (id) => {
    setCategoryId(id);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setCategoryId(null);
  };

  const handleConfirm = async () => {
    try {
      const url = `${window.env.REACT_APP_BASE_URL}category/`;
      const response = await axios.delete(url, {
        data: { id: categoryId, store_id: storeId },
        headers: {
          Authorization: `Bearer ${localStorage.getItem('jwt')}`,
          'Content-Type': 'application/json',
        },
        withCredentials: true,
      });
      if (response.status === 204) {
        setCategories(prevCategories => prevCategories.filter(category => category.id !== categoryId));
      } else {
        console.error('Échec de la suppression de la catégorie');
      }
    } catch (error) {
      console.error('Erreur :', error);
    }
    handleClose();
  };

  const handleEditCategory = async () => {
    try {
      const url = `${window.env.REACT_APP_BASE_URL}category/`;
      const response = await axios.put(
        url,
        {
          id: editingCategory.id,
          name: editCategoryName,
          store_id: storeId,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('jwt')}`,
            'Content-Type': 'application/json',
          },
          withCredentials: true,
        }
      );
      if (response.status === 200) {
        setCategories(prevCategories =>
          prevCategories.map(category =>
            category.id === editingCategory.id
              ? { ...category, name: editCategoryName }
              : category
          )
        );
        setEditingCategory(null);
        setEditCategoryName('');
      } else {
        console.error('Échec de la modification de la catégorie');
      }
    } catch (error) {
      console.error('Erreur :', error);
    }
  };

  const handleFileChange = (e) => {
    setNewCategoryImage(e.target.files[0]);
  };

  const onDragEnd = async (result) => {
    if (!result.destination) return;

    const reorderedCategories = Array.from(categories);
    const [movedCategory] = reorderedCategories.splice(result.source.index, 1);
    reorderedCategories.splice(result.destination.index, 0, movedCategory);

    setCategories(reorderedCategories);

    try {
      const url = `${window.env.REACT_APP_BASE_URL}update_category_order/`;
      await axios.put(url, { categories: reorderedCategories }, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('jwt')}`,
          'Content-Type': 'application/json',
        },
        withCredentials: true,
      });
    } catch (error) {
      console.error('Error updating category order:', error);
      setError('Erreur lors de la mise à jour de l\'ordre des catégories');
      setTimeout(() => setError(''), 1000);
    }
  };

return (
    <div className="categories">
      <div className="add-category">
        <input
          type="text"
          value={newCategory}
          onChange={(e) => setNewCategory(e.target.value)}
          placeholder="Nom de la catégorie"
        />
        <div className="file-input-container">
          <input type="file" id="file-upload" onChange={handleFileChange} />
          <label htmlFor="file-upload" className="file-label">
            <FaUpload className="fa-upload" />
          </label>
          <span className="tooltip">Télécharger une photo</span>
        </div>
        <button onClick={handleAddCategory}>AJOUTER</button>
      </div>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="categories">
          {(provided) => (
            <table {...provided.droppableProps} ref={provided.innerRef}>
              <thead>
                <tr>
                  <th>Nom de la catégorie</th>
                  <th>Image</th>
                  <th>Action</th>
                </tr>
              </thead>
              {error && (
                <div className="errorResult">
                  Erreur : {error}
                </div>
              )}
              <tbody>
                {categories.map((category, index) => (
                  <Draggable key={category.id} draggableId={category.id.toString()} index={index}>
                    {(provided) => (
                      <tr ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                        <td>
                          {editingCategory && editingCategory.id === category.id ? (
                            <input
                              type="text"
                              value={editCategoryName}
                              onChange={(e) => setEditCategoryName(e.target.value)}
                            />
                          ) : (
                            category.name
                          )}
                        </td>
                        <td>
                          {category.image_url ? (
                            <img src={category.image_url} alt={category.name} className="table-image"/>
                          ) : (
                            <p>Pas d'image</p>
                          )}
                        </td>
                        <td>
                          {editingCategory && editingCategory.id === category.id ? (
                            <button className="save-button" onClick={handleEditCategory}>
                              Enregistrer
                            </button>
                          ) : (
                            <>
                              <FaEdit 
                                className="fa-edit"
                                onClick={() => {
                                  setEditingCategory(category);
                                  setEditCategoryName(category.name);
                                }}
                              />
                              <FaTrash
                                className="fa-trash"
                                onClick={() => handleOpen(category.id)}
                              />
                              <ConfirmDialog
                                open={open && categoryId === category.id}
                                onClose={handleClose}
                                onConfirm={handleConfirm}
                                msg={'Êtes-vous sûr de vouloir supprimer cette catégorie ? Cette action est irréversible.'}
                              />
                            </>
                          )}
                        </td>
                      </tr>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </tbody>
            </table>
          )}
        </Droppable>
      </DragDropContext>
    </div>
  );
};

export default Categories;
