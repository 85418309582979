import React, { useState } from 'react';
import axios from 'axios';
import { FaEnvelope, FaLock } from 'react-icons/fa'; 
import { useNavigate } from 'react-router-dom';

import './Auth.css';
 
const Auth = ({ onLogin }) => {
  const [formData, setFormData] = useState({
    email: '',
    password: ''
  });

  const [error, setError] = useState(''); // State for error message
  const { email, password } = formData;
  const navigate = useNavigate();

  const onChange = e => setFormData({ ...formData, [e.target.name]: e.target.value });

  const handleLogin = async e => {
    e.preventDefault();
    try {
      const url = `${window.env.REACT_APP_BASE_URL}login`;
      const res = await axios.post(url, { email, password });
      const jwt = res.data.jwt;
      localStorage.setItem('jwt', jwt); // Store JWT token in localStorage
      axios.defaults.headers.common['Authorization'] = `Bearer ${jwt}`;
      onLogin(); 
      navigate('/stores');
    } catch (err) {
      console.error(err.response.data);
      setError('Identifiants incorrects, Veuillez réessayer.');
    }
  };

  return (
    <div className='header'>
      <div className="header-contents">
        <div className="form-container">
          <h2>Login</h2>
          <form onSubmit={handleLogin}>
            <div className="form-group">
              <div className="input-with-icon">
                <FaEnvelope className="input-icon" />
                <input 
                  type="email" 
                  name="email"
                  value={email} 
                  onChange={onChange} 
                  placeholder="Saisissez votre email" 
                  required
                />
              </div>
            </div>
            <div className="form-group">
              <div className="input-with-icon">
                <FaLock className="input-icon" />
                <input 
                  type="password" 
                  name="password"
                  value={password} 
                  onChange={onChange} 
                  placeholder="Saisissez votre mot de passe" 
                  required
                />
              </div>
            </div>
            <div className='btns flex'>
              <div className='singleBtn'>
                <button type="submit" className="button">Connexion</button>
              </div>
            </div>
            {error && <p className="error-message">{error}</p>} {/* Display error message if any */}
          </form>
        </div>
      </div>
    </div>
  );
};

export default Auth;
