import React, { useState, useRef, useEffect } from 'react';
import './Navbar.css';
import { FaUser, FaTimes } from 'react-icons/fa';
import axios from 'axios';
import { NavLink, useLocation } from 'react-router-dom';
// import Cookies from 'js-cookie';

const Navbar = ({ onLogout }) => {
  const [showLogout, setShowLogout] = useState(false);
  const [showResetPassword, setShowResetPassword] = useState(false);
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');
  const [resetSuccess, setResetSuccess] = useState('');
  const [nbrStores, setNbrStores] = useState('');
  const dropdownRef = useRef(null);
  const location = useLocation();

  useEffect(() => {
    const fetchStores = async () => {
      try {
        const url = `${window.env.REACT_APP_BASE_URL}user_stores`;
        const res = await axios.get(url, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('jwt')}`,
            'Content-Type': 'application/json',
          },
          withCredentials: true,
        });
        setNbrStores(res.data.stores.length);
      } catch (err) {
        console.error('Error fetching stores:', err);
        setError(`Failed to fetch stores. Please try again later. Error: ${err.message}, Details: ${err.response?.data}`);
      }
    };

    fetchStores();
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowLogout(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [dropdownRef]);

  // const handleLogout = async () => {
  //   try {
  //     const url = `${window.env.REACT_APP_BASE_URL}logout`;
      
  //     const response = await axios.post(url, {
  //       headers: {
  //         'Content-Type': 'application/json',
  //         'Authorization': 'Bearer ' + localStorage.getItem('jwt')
  //       },
  //       withCredentials: true,
  //     });


  //     if (response.status === 200) {
  //       onLogout();
  //     } else {
  //       console.error('Failed to log out');
  //     }
  //   } catch (error) {
  //     console.error('Error:', error);
  //   }
  // };

  const handleLogout = async () => {
    fetch(`${window.env.REACT_APP_BASE_URL}logout`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + localStorage.getItem('jwt')
        }
    })
    .then(response => {
        if (response.status === 200) {
            localStorage.removeItem('jwt');  // Remove the JWT token
            onLogout(); // Redirect to login page
        } else {
            alert('Logout failed');
        }
    })
    .catch(error => console.error('Error:', error));
}


  const handlePasswordReset = async (e) => {
    e.preventDefault();
    if (newPassword !== confirmPassword) {
      setError('Les mots de passe ne correspondent pas.');
      return;
    }
    try {
      const token = localStorage.getItem('jwt');
      if (!token) {
        setError('JWT token manquant, veuillez vous reconnecter.');
        return;
      }
      const res = await axios.put('http://localhost:8000/api/resetPassword', {
        currentPassword,
        newPassword
      }, {
        headers: {
          'Authorization': `Bearer ${token}`
        },
        withCredentials: true
      });

      if (res.data.message === 'Password edited successfully') {
        setResetSuccess('Mot de passe modifié avec succès.');
        setError('');
        setShowResetPassword(false);
        setTimeout(() => {
          setResetSuccess('');
        }, 5000);
      } else {
        setError(res.data.error);
      }
    } catch (err) {
      console.error(err.response.data);
      setError('Erreur lors de la modification du mot de passe.');
    }
  };

  return (
    <div className='navbar'>
      <p className='logo'>RoyalNaan</p>
      <div className='profile-container' ref={dropdownRef}>
        <FaUser className='profile' onClick={() => setShowLogout(!showLogout)} />
        {showLogout && (
          <div className='logout-menu'>
            {nbrStores>1 && location.pathname !== '/stores'  && 
              <NavLink to={'/stores'}>
                <div className='logout-item'>Changer le store</div>
              </NavLink>
            }
            <div className='logout-item' onClick={() => setShowResetPassword(true)}>Changer le mot de passe</div>
            <div className='logout-item' onClick={handleLogout}>Déconnecter</div>
          </div>
        )}
      </div>
      {showResetPassword && (
        <div className='modal'>
          <div className='modal-content'>
            <div className='modal-header'>
              <h2>Réinitialiser le mot de passe</h2>
              <FaTimes className='close-icon' onClick={() => setShowResetPassword(false)} />
            </div>
            <form onSubmit={handlePasswordReset}>
              <div className='form-groupp'>
                <input 
                  type='password' 
                  name='currentPassword' 
                  value={currentPassword} 
                  onChange={(e) => setCurrentPassword(e.target.value)} 
                  placeholder='Saisissez votre mot de passe actuel' 
                  required 
                />
              </div>
              <div className='form-groupp'>
                <input 
                  type='password' 
                  name='newPassword' 
                  value={newPassword} 
                  onChange={(e) => setNewPassword(e.target.value)} 
                  placeholder='Saisissez votre nouveau mot de passe' 
                  required 
                />
              </div>
              <div className='form-groupp'>
                <input 
                  type='password' 
                  name='confirmPassword' 
                  value={confirmPassword} 
                  onChange={(e) => setConfirmPassword(e.target.value)} 
                  placeholder='Confirmez votre nouveau mot de passe' 
                  required 
                />
              </div>
              <div className='btns flex'>
                <div className='singleBtn'>
                  <button type='submit' className='button'>Réinitialiser le mot de passe</button>
                </div>
              </div>
              {error && <p className='error-message'>{error}</p>}
              {resetSuccess && <p className='success-message'>{resetSuccess}</p>}
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default Navbar;
